import React from 'react';
import './index.css';
import * as PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getCourseDetails } from '../../actions/courses';
import CircularProgress from '../../components/CircularProgress';
import logo from '../../assets/logo.png';
import NoData from '../../components/NoData';
import ClassNames from 'classnames';
import variables from '../../utils/variables';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import VideoOnLoad from '../../components/ImageOnLoad/VideoOnLoad';
import interactiveIcon from '../../assets/interactive.svg';
import { ReactComponent as PlayButton } from '../../assets/register_iv.svg';
import { Button } from '@mui/material';
import { config } from '../../config';
import { aminoSignTx, aminoSignTxLeap, cosmoStationSign } from '../../helper';
import {
    addInteractiveVideoSession, fetchDeviceID,
    hideIMPInformationDialog,
    hideTokenGatedContentDialog, showIMPInformationDialog, showTokenGatedContentDialog,
} from '../../actions/interactiveVideo';
import { showMessage } from '../../actions/snackbar';
import { showConnectAccountDialog, showConnectDialog } from '../../actions/navbar';
import { fetchCollectionMyNFTs } from '../../actions/gatedAccess';
import { walletConnectSign, aminoSignTxMetaMask } from '../../actions/account/wallet';
import IMPInformationDialog from '../VideosList/IMPInformationDialog';
import TokenGatedContentDialog from '../VideosList/TokenGatedContentDialog';
import DownArrow from '../../assets/singleCourse/down_arrow.svg';
import UpArrowIcon from '../../assets/singleCourse/up_arrow.svg';
import { formatDuration } from '../../utils/numbers';

class SingleCourse extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            show: false,
            videoPlayed: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleVerify = this.handleVerify.bind(this);
        this.handleInteractive = this.handleInteractive.bind(this);
        this.addSession = this.addSession.bind(this);
        this.handleCosmostation = this.handleCosmostation.bind(this);
        this.signWithCosmosAccount = this.signWithCosmosAccount.bind(this);
        this.walletConnectSign = this.walletConnectSign.bind(this);
        this.handleMetaMaskLeap = this.handleMetaMaskLeap.bind(this);
        this.handleLeap = this.handleLeap.bind(this);
        this.togglePlay = this.togglePlay.bind(this);
    }

    componentDidMount () {
        // const id = this.props.match.params.id;
    }

    componentWillUnmount () {

    }

    handleChange () {
        this.setState({
            show: !this.state.show,
        });
    }

    handleReadMore (value) {
        this.setState({
            [value]: !this.state[value],
        });
    }

    handleVerify (video) {
        if (!this.props.address) {
            this.props.showConnectDialog(false);
            // this.props.showMessage('Connect Account');
            return;
        }

        this.props.showTokenGatedContentDialog();
        if (video.akash_edu_config && video.akash_edu_config.denom_id) {
            this.props.fetch(video.akash_edu_config.denom_id, this.props.address, (result) => {
                if (result && result.length) {
                    this.props.hideTokenGatedContentDialog();
                    this.props.history.push(`/videos/${video._id}`);
                }
            });
        }
    }

    handleInteractive (video) {
        if (!this.props.address) {
            // this.props.showConnectAccountDialog(true);
            this.props.showConnectDialog(false);
            // this.props.showMessage('Connect Account');
            return;
        }

        this.props.showTokenGatedContentDialog();
        if (video.akash_edu_config && video.akash_edu_config.denom_id) {
            this.props.fetch(video.akash_edu_config.denom_id, this.props.address, (result) => {
                if (result && result.length) {
                    this.props.hideTokenGatedContentDialog();
                    this.addSession(video);
                }
            });
        }
    }

    addSession (video) {
        if (!this.props.deviceID) {
            this.props.fetchDeviceID((error, deviceID) => {
                if (error) {
                    return;
                }

                const lockType = localStorage.getItem('akt_edu_connector');
                if (lockType === 'k') {
                    this.signWithCosmosAccount(deviceID, video);
                } else if (lockType === 'wc') {
                    this.walletConnectSign(deviceID, video);
                } else if (lockType === 'cs') {
                    this.handleCosmostation(deviceID, video);
                } else if (lockType === 'leap') {
                    this.handleLeap(deviceID, video);
                } else if (lockType === 'mml') {
                    this.handleMetaMaskLeap(deviceID, video);
                }
            });
        } else {
            const lockType = localStorage.getItem('akt_edu_connector');
            if (lockType === 'k') {
                this.signWithCosmosAccount(this.props.deviceID, video);
            } else if (lockType === 'wc') {
                this.walletConnectSign(this.props.deviceID, video);
            } else if (lockType === 'cs') {
                this.handleCosmostation(this.props.deviceID, video);
            } else if (lockType === 'leap') {
                this.handleLeap(this.props.deviceID, video);
            } else if (lockType === 'mml') {
                this.handleMetaMaskLeap(this.props.deviceID, video);
            }
        }
    }

    handleCosmostation (deviceID, video) {
        const tx = {
            msg: {
                type: 'omniflix/MsgSign',
                value: {
                    address: this.props.address,
                },
            },
            fee: {
                amount: [{
                    denom: config.COIN_MINIMAL_DENOM,
                    amount: '0',
                }],
                gas: '1',
            },
            memo: video._id,
        };

        cosmoStationSign(tx, this.props.address, (error, result) => {
            if (error) {
                this.props.showMessage(error);
                return;
            }

            this.props.addInteractiveVideoSession(video._id, {
                accountAddress: this.props.address,
                deviceFingerprint: deviceID,
                sign: {
                    pub_key: result && result.pub_key,
                    signature: result && result.signature,
                },
            }, (error) => {
                if (error) {
                    // this.props.showMessage('Error while adding session');
                    return;
                }

                this.props.showIMPInformationDialog(video);
            });
        });
    }

    handleLeap (deviceID, video) {
        const tx = {
            msg: {
                type: 'omniflix/MsgSign',
                value: {
                    address: this.props.address,
                },
            },
            fee: {
                amount: [{
                    denom: config.COIN_MINIMAL_DENOM,
                    amount: '0',
                }],
                gas: '1',
            },
            memo: video._id,
        };

        aminoSignTxLeap(tx, this.props.address, (error, result) => {
            if (window.keplr) {
                window.keplr.defaultOptions = {};
            }
            if (error) {
                this.props.showMessage(error);
                return;
            }

            this.props.addInteractiveVideoSession(video._id, {
                accountAddress: this.props.address,
                deviceFingerprint: deviceID,
                sign: result && result.signature,
            }, (error) => {
                if (error) {
                    // props.showMessage('Error while adding session');
                    return;
                }

                this.props.showIMPInformationDialog(video);
            });
        });
    }

    signWithCosmosAccount (deviceID, video) {
        if (window.keplr) {
            window.keplr.defaultOptions = {
                sign: {
                    preferNoSetFee: true,
                    preferNoSetMemo: true,
                },
            };
        }

        const tx = {
            msg: {
                type: 'omniflix/MsgSign',
                value: {
                    address: this.props.address,
                },
            },
            fee: {
                amount: [{
                    denom: config.COIN_MINIMAL_DENOM,
                    amount: '0',
                }],
                gas: '1',
            },
            memo: video._id,
        };

        aminoSignTx(tx, this.props.address, (error, result) => {
            if (window.keplr) {
                window.keplr.defaultOptions = {};
            }
            if (error) {
                this.props.showMessage(error);
                return;
            }

            this.props.addInteractiveVideoSession(video._id, {
                accountAddress: this.props.address,
                deviceFingerprint: deviceID,
                sign: result && result.signature,
            }, (error) => {
                if (error) {
                    // this.props.showMessage('Error while adding session');
                    return;
                }

                this.props.showIMPInformationDialog(video);
            });
        });
    }

    walletConnectSign (deviceID, video) {
        const tx = {
            msg: {
                type: 'omniflix/MsgSign',
                value: {
                    address: this.props.address,
                },
            },
            fee: {
                amount: [{
                    denom: config.COIN_MINIMAL_DENOM,
                    amount: '0',
                }],
                gas: '1',
            },
            preferNoSetFee: true,
            memo: video._id,
        };

        this.props.walletConnectSign(this.props.walletConnector, tx, this.props.address, (result) => {
            if (result) {
                this.props.addInteractiveVideoSession(video._id, {
                    accountAddress: this.props.address,
                    deviceFingerprint: deviceID,
                    sign: result && result.signature,
                }, (error) => {
                    if (error) {
                        // this.props.showMessage('Error while adding session');
                        return;
                    }

                    this.props.showIMPInformationDialog(video);
                });
            }
        });
    }

    handleMetaMaskLeap (deviceID, video) {
        const tx = {
            msg: {
                type: 'omniflix/MsgSign',
                value: {
                    address: this.props.address,
                },
            },
            fee: {
                amount: [{
                    denom: config.COIN_MINIMAL_DENOM,
                    amount: '0',
                }],
                gas: '1',
            },
            memo: video._id,
        };

        this.props.aminoSignTxMetaMask(tx, this.props.address, (result) => {
            if (window.keplr) {
                window.keplr.defaultOptions = {};
            }

            this.props.addInteractiveVideoSession(video._id, {
                accountAddress: this.props.address,
                deviceFingerprint: deviceID,
                sign: result && result.signature,
            }, (error) => {
                if (error) {
                    // props.showMessage('Error while adding session');
                }

                this.props.showIMPInformationDialog(video);
            });
        });
    }

    togglePlay () {
        if (!this.state.videoPlayed) {
            const video = document.getElementById('video-background');
            video.play();
            this.setState({
                videoPlayed: true,
            });
        }
    }

    render () {
        const id = this.props.match && this.props.match.params && this.props.match.params.id;
        const courseInfo = this.props.courses && this.props.courses[id] && this.props.courses[id].length &&
            this.props.courses[id].find((val) => val && val.course_info);
        const { videoPlayed } = this.state;

        return (
            <div className="single_course">
                <div className="inner_section">
                    <div className="header">
                        {courseInfo && courseInfo.course_info
                            ? <div className="header_tag" onClick={() => this.props.history.goBack()}>
                                <ArrowBackIosNewIcon/>
                                <span className="course_title">{courseInfo.course_info && courseInfo.course_info.name}</span>
                            </div> : null}
                    </div>
                    {courseInfo && courseInfo.course_info
                        ? <div className="course_section">
                            <div className="left_section">
                                <div>
                                    <h2>{variables[this.props.lang].course_description}</h2>
                                    <div className="show_more_section">
                                        <pre
                                            className={ClassNames('show_more_content', this.state.show ? 'show_more' : '')}>
                                            {courseInfo.course_info && courseInfo.course_info.description}
                                        </pre>
                                        {courseInfo.course_info && courseInfo.course_info.description &&
                                        courseInfo.course_info && courseInfo.course_info.description.length > 300
                                            ? <div
                                                className="show_more_button"
                                                onClick={this.handleChange}>
                                                {this.state.show
                                                    ? <>
                                                        {variables[this.props.lang].read_less}
                                                        <img alt="UpArrow" src={UpArrowIcon}/>
                                                    </>
                                                    : <>
                                                        {variables[this.props.lang].read_more}
                                                        <img alt="down arrow" src={DownArrow}/>
                                                    </>}
                                            </div> : null}
                                    </div>
                                </div>
                                <div className="course_instructions">
                                    <h2>{variables[this.props.lang].course_instructors}</h2>
                                    <div className="section_array">
                                        {courseInfo.course_info && courseInfo.course_info.instructors &&
                                            courseInfo.course_info.instructors.length > 0 &&
                                            courseInfo.course_info.instructors.map((instru, instruIndex) => (
                                                <div key={instruIndex} className="section">
                                                    <img alt="" src={instru.image} />
                                                    <div>
                                                        <span>{instru.name}</span>
                                                        <p>{instru.description}</p>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            </div>
                            <div className="right_section">
                                <div className="image_section">
                                    <div
                                        className={ClassNames('list_section2 inline_video_section', videoPlayed ? 'video_playing' : '')}
                                        onClick={this.togglePlay}>
                                        <VideoOnLoad
                                            className={videoPlayed ? 'video_playing' : ''}
                                            preview={courseInfo && courseInfo.course_info && courseInfo.course_info.poster
                                                ? courseInfo.course_info.poster : null}
                                            src={courseInfo.course_info && courseInfo.course_info.preview}
                                            videoPlayed={videoPlayed || null}/>
                                        {!videoPlayed &&
                                            <div className="video_preview">
                                                <Button>
                                                    <PlayButton/>
                                                </Button>
                                                <p>Preview this course</p>
                                            </div>}
                                    </div>
                                    {/* <img */}
                                    {/*     alt="thumbnail" */}
                                    {/*     className="thumbnail" */}
                                    {/*     src={item.course_info && item.course_info.preview */}
                                    {/*         ? item.course_info.preview : logo}/> */}
                                </div>
                            </div>
                        </div> : null}
                </div>
                <div className="courses_list">
                    {this.props.inProgress
                        ? <CircularProgress/>
                        : this.props.courses[id] && this.props.courses[id].length
                            ? this.props.courses[id].map((data, dataIndex) => {
                                return (
                                    <div
                                        key={dataIndex}
                                        className="course_details">
                                        <span className="index_border"/>
                                        <div className="indexing">
                                            <span>
                                                {dataIndex + 1}
                                            </span>
                                        </div>
                                        <div
                                            className="course_section_main"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (data.akash_edu_config && data.akash_edu_config.is_interactive) {
                                                    this.handleInteractive(data);
                                                } else {
                                                    this.handleVerify(data);
                                                }
                                            }}>
                                            <div className="image_section">
                                                <img
                                                    alt="thumbnail"
                                                    className="thumbnail"
                                                    src={data.asset && data.asset.thumbnail && data.asset.thumbnail.horizontal
                                                        ? data.asset.thumbnail.horizontal : logo}/>
                                                {data.akash_edu_config && data.akash_edu_config.is_interactive &&
                                                    <div className="interactive_icon">
                                                        <img alt="interactive" src={interactiveIcon}/>
                                                    </div>}
                                                {data.akash_edu_config && data.akash_edu_config.is_interactive
                                                    ? <Button
                                                        className="player_hover"
                                                        onClick={() => this.handleInteractive(data)}>
                                                        <PlayButton/>
                                                    </Button>
                                                    : <Button
                                                        className="player_hover"
                                                        onClick={() => this.handleVerify(data)}>
                                                        <PlayButton/>
                                                    </Button>}
                                            </div>
                                            <div className="course_info">
                                                <p className="asset_name">
                                                    <span className="title">{data && data.name}</span>
                                                    {data.akash_edu_config && data.akash_edu_config.is_interactive === true
                                                        ? <span className="is_interactive">
                                                            {variables[this.props.lang].quiz}
                                                        </span>
                                                        : <span className="is_interactive">
                                                            {variables[this.props.lang].explainer_video}
                                                        </span>
                                                    }
                                                    {data.asset && data.asset.file && data.asset.file.length
                                                        ? <span className="duration">
                                                            {formatDuration(data.asset.file.length)}
                                                        </span>
                                                        : null}
                                                </p>
                                                {data.asset && data.asset.description &&
                                                    <div className="show_more_section">
                                                        <pre
                                                            className={ClassNames('show_more_content', this.state['description' + dataIndex] ? 'show_more' : '')}
                                                            style={data.asset && data.asset.description && data.asset.description.length < 300 ? { height: 'unset' } : {}}>
                                                            {data.asset && data.asset.description}
                                                        </pre>
                                                        {data.asset && data.asset.description && data.asset.description.length > 300
                                                            ? <div
                                                                className="show_more_button"
                                                                onClick={() => this.handleReadMore('description' + dataIndex)}>
                                                                {this.state['description' + dataIndex]
                                                                    ? <>
                                                                        {variables[this.props.lang].read_less}
                                                                        <img alt="UpArrow" src={UpArrowIcon}/>
                                                                    </>
                                                                    : <>
                                                                        {variables[this.props.lang].read_more}
                                                                        <img alt="down arrow" src={DownArrow}/>
                                                                    </>}
                                                            </div> : null}
                                                    </div>}
                                            </div>
                                        </div>
                                    </div>
                                );
                            }) : <NoData/>}
                </div>
                <IMPInformationDialog/>
                <TokenGatedContentDialog/>
            </div>
        );
    }
}

SingleCourse.propTypes = {
    addInteractiveVideoSession: PropTypes.func.isRequired,
    address: PropTypes.string.isRequired,
    aminoSignTxMetaMask: PropTypes.func.isRequired,
    courses: PropTypes.object.isRequired,
    fetch: PropTypes.func.isRequired,
    fetchDeviceID: PropTypes.func.isRequired,
    getCourseDetails: PropTypes.func.isRequired,
    hideIMPInformationDialog: PropTypes.func.isRequired,
    hideTokenGatedContentDialog: PropTypes.func.isRequired,
    history: PropTypes.shape({
        goBack: PropTypes.func.isRequired,
        push: PropTypes.func.isRequired,
    }).isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    list: PropTypes.array.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    nftSList: PropTypes.object.isRequired,
    showConnectAccountDialog: PropTypes.func.isRequired,
    showConnectDialog: PropTypes.func.isRequired,
    showIMPInformationDialog: PropTypes.func.isRequired,
    showMessage: PropTypes.func.isRequired,
    showTokenGatedContentDialog: PropTypes.func.isRequired,
    walletConnectSign: PropTypes.func.isRequired,
    deviceID: PropTypes.string,
    walletConnector: PropTypes.object,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        address: state.account.address,
        courses: state.navbar.videosList.courses,
        inProgress: state.navbar.videosList.inProgress,
        deviceID: state.interactiveVideo.deviceID.id,
        walletConnector: state.account.wallet.connection.walletConnector,
        list: state.navbar.videosList.value,
        nftSList: state.myAccount.userNFTs.result,
    };
};

const actionsToProps = {
    getCourseDetails,
    addInteractiveVideoSession,
    hideIMPInformationDialog,
    hideTokenGatedContentDialog,
    showTokenGatedContentDialog,
    showIMPInformationDialog,
    showMessage,
    showConnectAccountDialog,
    fetch: fetchCollectionMyNFTs,
    fetchDeviceID,
    walletConnectSign,
    showConnectDialog,
    aminoSignTxMetaMask,
};

export default withRouter(connect(stateToProps, actionsToProps)(SingleCourse));

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import logo from '../../assets/logo.png';
import './index.css';
// import Resize from '../InteractiveVideos/Resize';
import { config } from '../../config';
import { aminoSignTx, aminoSignTxLeap, cosmoStationSign } from '../../helper';
import {
    addInteractiveVideoSession,
    fetchDeviceID,
    hideIMPInformationDialog,
    hideTokenGatedContentDialog,
    showIMPInformationDialog,
    showTokenGatedContentDialog,
} from '../../actions/interactiveVideo';
import { showMessage } from '../../actions/snackbar';
import { fetchCollectionMyNFTs } from '../../actions/gatedAccess';
import { showConnectAccountDialog, showConnectDialog } from '../../actions/navbar';
import { walletConnectSign, aminoSignTxMetaMask } from '../../actions/account/wallet';
import { Button } from '@material-ui/core';
import { ReactComponent as InteractIcon } from '../../assets/home/interact.svg';
// import { ReactComponent as TokenGated } from '../../assets/token_gated.svg';
import { ReactComponent as PlayButton } from '../../assets/register_iv.svg';
import { ReactComponent as ShareIcon } from '../../assets/home/shareIcon.svg';
import { showShareDialog } from '../../actions/home';

const List = (props) => {
    const [handleDialog, setHandleDialog] = useState(false);

    useEffect(() => {
        const elements = document.getElementById('scroll-bar');
        for (let i = 0, len = elements.length; i < len; i++) {
            elements[i].style.overflow = 'hidden';
        }

        return () => {
            for (let i = 0, len = elements.length; i < len; i++) {
                elements[i].style.overflow = 'unset';
            }
        };
    }, []);

    useEffect(() => {
        if (props.address && props.address !== '' && handleDialog) {
            const registration = props.list && props.list.length && props.list.find((val) => val && val.akash_edu_config && val.akash_edu_config.is_admission);
            handleVideo(registration);
        } else if (!props.address || props.address === '') {
            setHandleDialog(false);
        }
    }, [props.address]);

    const handleVideo = (video) => {
        if (!props.address) {
            props.showConnectDialog(false);
            setHandleDialog(true);
            return;
        }

        if (Object.keys(props.nftSList).length) {
            props.showMessage('You already have the Course Access NFT');
            return;
        }

        if (video && video.akash_edu_config && !video.akash_edu_config.denom_id) {
            addSession(video);
            return;
        }

        if (video.akash_edu_config && video.akash_edu_config.is_interactive) {
            handleInteractive(video);
        } else if (video.akash_edu_config) {
            handleVerify(video);
        }
    };

    const handleVerify = (video) => {
        if (!props.address) {
            props.showMessage('Connect Account');
            return;
        }

        props.showTokenGatedContentDialog();
        if (video.akash_edu_config && video.akash_edu_config.denom_id) {
            props.fetch(video.akash_edu_config.denom_id, props.address, (result) => {
                if (result && result.length) {
                    props.history.push(`/videos/${video._id}`);
                    props.hideTokenGatedContentDialog();
                }
            });
        }
    };

    const handleInteractive = (video) => {
        if (!props.address) {
            // props.showConnectAccountDialog(true);
            props.showMessage('Connect Account');
            return;
        }

        props.showTokenGatedContentDialog();
        if (video.akash_edu_config && video.akash_edu_config.denom_id) {
            props.fetch(video.akash_edu_config.denom_id, props.address, (result) => {
                if (result && result.length) {
                    props.hideTokenGatedContentDialog();
                    addSession(video);
                }
            });
        }
    };

    const addSession = (video) => {
        if (!props.deviceID) {
            props.fetchDeviceID((error, deviceID) => {
                if (error) {
                    return;
                }

                const lockType = localStorage.getItem('akt_edu_connector');
                if (lockType === 'k') {
                    signWithCosmosAccount(deviceID, video);
                } else if (lockType === 'wc') {
                    walletConnectSign(deviceID, video);
                } else if (lockType === 'cs') {
                    handleCosmostation(deviceID, video);
                } else if (lockType === 'leap') {
                    handleLeap(deviceID, video);
                } else if (lockType === 'mml') {
                    handleMetaMaskLeap(deviceID, video);
                }
            });
        } else {
            const lockType = localStorage.getItem('akt_edu_connector');
            if (lockType === 'k') {
                signWithCosmosAccount(props.deviceID, video);
            } else if (lockType === 'wc') {
                walletConnectSign(props.deviceID, video);
            } else if (lockType === 'cs') {
                handleCosmostation(props.deviceID, video);
            } else if (lockType === 'leap') {
                handleLeap(props.deviceID, video);
            } else if (lockType === 'mml') {
                handleMetaMaskLeap(props.deviceID, video);
            }
        }
    };

    const handleCosmostation = (deviceID, video) => {
        const tx = {
            msg: {
                type: 'omniflix/MsgSign',
                value: {
                    address: props.address,
                },
            },
            fee: {
                amount: [{
                    denom: config.COIN_MINIMAL_DENOM,
                    amount: '0',
                }],
                gas: '1',
            },
            memo: video._id,
        };

        cosmoStationSign(tx, props.address, (error, result) => {
            if (error) {
                props.showMessage(error);
                return;
            }

            props.addInteractiveVideoSession(video._id, {
                accountAddress: props.address,
                deviceFingerprint: deviceID,
                sign: {
                    pub_key: result && result.pub_key,
                    signature: result && result.signature,
                },
            }, (error) => {
                if (error) {
                    // props.showMessage('Error while adding session');
                    return;
                }

                handleInteract(video);
            });
        });
    };

    const handleLeap = (deviceID, video) => {
        const tx = {
            msg: {
                type: 'omniflix/MsgSign',
                value: {
                    address: props.address,
                },
            },
            fee: {
                amount: [{
                    denom: config.COIN_MINIMAL_DENOM,
                    amount: '0',
                }],
                gas: '1',
            },
            memo: video._id,
        };

        aminoSignTxLeap(tx, props.address, (error, result) => {
            if (window.keplr) {
                window.keplr.defaultOptions = {};
            }
            if (error) {
                props.showMessage(error);
                return;
            }

            props.addInteractiveVideoSession(video._id, {
                accountAddress: props.address,
                deviceFingerprint: deviceID,
                sign: result && result.signature,
            }, (error) => {
                if (error) {
                    // props.showMessage('Error while adding session');
                    return;
                }

                handleInteract(video);
            });
        });
    };

    const signWithCosmosAccount = (deviceID, video) => {
        if (window.keplr) {
            window.keplr.defaultOptions = {
                sign: {
                    preferNoSetFee: true,
                    preferNoSetMemo: true,
                },
            };
        }

        const tx = {
            msg: {
                type: 'omniflix/MsgSign',
                value: {
                    address: props.address,
                },
            },
            fee: {
                amount: [{
                    denom: config.COIN_MINIMAL_DENOM,
                    amount: '0',
                }],
                gas: '1',
            },
            memo: video._id,
        };

        aminoSignTx(tx, props.address, (error, result) => {
            if (window.keplr) {
                window.keplr.defaultOptions = {};
            }
            if (error) {
                props.showMessage(error);
                return;
            }

            props.addInteractiveVideoSession(video._id, {
                accountAddress: props.address,
                deviceFingerprint: deviceID,
                sign: result && result.signature,
            }, (error) => {
                if (error) {
                    // props.showMessage('Error while adding session');
                    return;
                }

                handleInteract(video);
            });
        });
    };

    const walletConnectSign = (deviceID, video) => {
        const tx = {
            msg: {
                type: 'omniflix/MsgSign',
                value: {
                    address: props.address,
                },
            },
            fee: {
                amount: [{
                    denom: config.COIN_MINIMAL_DENOM,
                    amount: '0',
                }],
                gas: '1',
            },
            preferNoSetFee: true,
            memo: video._id,
        };

        props.walletConnectSign(props.walletConnector, tx, props.address, (result) => {
            if (result) {
                props.addInteractiveVideoSession(video._id, {
                    accountAddress: props.address,
                    deviceFingerprint: deviceID,
                    sign: result && result.signature,
                }, (error) => {
                    if (error) {
                        // props.showMessage('Error while adding session');
                        return;
                    }

                    handleInteract(video);
                });
            }
        });
    };

    const handleMetaMaskLeap = (deviceID, video) => {
        const tx = {
            msg: {
                type: 'omniflix/MsgSign',
                value: {
                    address: props.address,
                },
            },
            fee: {
                amount: [{
                    denom: config.COIN_MINIMAL_DENOM,
                    amount: '0',
                }],
                gas: '1',
            },
            memo: video._id,
        };

        props.aminoSignTxMetaMask(tx, props.address, (result) => {
            if (window.keplr) {
                window.keplr.defaultOptions = {};
            }

            props.addInteractiveVideoSession(video._id, {
                accountAddress: props.address,
                deviceFingerprint: deviceID,
                sign: result && result.signature,
            }, (error) => {
                if (error) {
                    // props.showMessage('Error while adding session');
                    return;
                }

                handleInteract(video);
            });
        });
    };

    const handleInteract = (video) => {
        props.history.push(`/interactive-videos/${video && video._id}`);
    };

    const registration = props.list && props.list.length && props.list.find((val) => val && val.akash_edu_config && val.akash_edu_config.is_admission);
    return (
        registration && Object.keys(registration).length
            ? Object.keys(props.nftSList).length
                ? <>
                    <div
                        key={registration.asset && registration.asset._id}
                        className="video_section"
                        onClick={() => props.showShareDialog()}>
                        <div className="video_section_thumbnail">
                            <div className="video_section_overlay"></div>
                            <img
                                alt="thumbnail"
                                className="video_section_image"
                                src={registration.asset && registration.asset.thumbnail && registration.asset.thumbnail.horizontal
                                    ? registration.asset.thumbnail.horizontal : logo}/>
                        </div>
                        <div className="video_info">
                            <p className="module">{registration && registration.asset && registration.asset.module}</p>
                            <p className="video_name">
                                {registration && registration.asset && registration.asset.name}
                            </p>
                            <Button
                                className="interact_button share_button">
                                <ShareIcon />
                                Share
                            </Button>
                        </div>
                    </div>
                </>
                : <>
                    <div
                        key={registration.asset && registration.asset._id}
                        className="video_section"
                        onClick={() => handleVideo(registration)}>
                        <div className="video_section_thumbnail">
                            <div className="video_section_overlay"></div>
                            <PlayButton/>
                            <img
                                alt="thumbnail"
                                className="video_section_image"
                                src={registration.asset && registration.asset.thumbnail && registration.asset.thumbnail.horizontal
                                    ? registration.asset.thumbnail.horizontal : logo}/>
                        </div>
                        <div className="video_info">
                            <p className="module">{registration && registration.asset && registration.asset.module}</p>
                            <p className="video_name">
                                {registration && registration.asset && registration.asset.name}
                            </p>
                            {registration.akash_edu_config && registration.akash_edu_config.is_interactive
                                ? <Button className="interact_button">
                                    <InteractIcon />
                                    Interact and Register Now
                                </Button>
                                : registration.akash_edu_config && registration.akash_edu_config.denom_id
                                    ? <Button className="watch_button">
                                        Verify to Watch
                                    </Button>
                                    : <Button className="watch_button">
                                        Watch
                                    </Button>}
                        </div>
                    </div>
                </>
            : null
    );
};

List.propTypes = {
    addInteractiveVideoSession: PropTypes.func.isRequired,
    aminoSignTxMetaMask: PropTypes.func.isRequired,
    courses: PropTypes.object.isRequired,
    fetch: PropTypes.func.isRequired,
    fetchDeviceID: PropTypes.func.isRequired,
    hideIMPInformationDialog: PropTypes.func.isRequired,
    hideTokenGatedContentDialog: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    list: PropTypes.array.isRequired,
    nftSList: PropTypes.object.isRequired,
    showConnectAccountDialog: PropTypes.func.isRequired,
    showConnectDialog: PropTypes.func.isRequired,
    showIMPInformationDialog: PropTypes.func.isRequired,
    showMessage: PropTypes.func.isRequired,
    showShareDialog: PropTypes.func.isRequired,
    showTokenGatedContentDialog: PropTypes.func.isRequired,
    size: PropTypes.number.isRequired,
    walletConnectSign: PropTypes.func.isRequired,
    address: PropTypes.string,
    deviceID: PropTypes.string,
    walletConnector: PropTypes.object,
};

const stateToProps = (state) => {
    return {
        address: state.account.address,
        deviceID: state.interactiveVideo.deviceID.id,
        size: state.interactiveVideo.windowSize,
        walletConnector: state.account.wallet.connection.walletConnector,
        list: state.navbar.videosList.value,
        courses: state.navbar.videosList.courses,
        nftSList: state.myAccount.userNFTs.result,
    };
};

const actionsToProps = {
    addInteractiveVideoSession,
    hideIMPInformationDialog,
    hideTokenGatedContentDialog,
    showTokenGatedContentDialog,
    showIMPInformationDialog,
    showMessage,
    showConnectAccountDialog,
    fetch: fetchCollectionMyNFTs,
    fetchDeviceID,
    walletConnectSign,
    showConnectDialog,
    showShareDialog,
    aminoSignTxMetaMask,
};

export default withRouter(connect(stateToProps, actionsToProps)(List));
